import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const Vacancy = props => (
  <tr>
    <td>{props.vacancy.postname}</td>
    <td>{props.vacancy.noofperson}</td>
    <td>{props.vacancy.description}</td>
    <td>
      <Link to={"/edit/"+props.vacancy._id}>edit</Link> | <a href="#" onClick={() => { props.deleteVacancy(props.vacancy._id) }}>delete</a>
    </td>
  </tr>
)

export default class VacancyList extends Component {
  constructor(props) {
    super(props);

    this.deleteVacancy = this.deleteVacancy.bind(this)

    this.state = {vacancy: []};
  }

  componentDidMount() {
    axios.get('http://localhost:5000/vacancy/')
      .then(response => {
        this.setState({ vacancy: response.data })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  deleteVacancy(id) {
    axios.delete('http://localhost:5000/vacancy/'+id)
      .then(response => { console.log(response.data)});

    this.setState({
      vacancy: this.state.vacancy.filter(el => el._id !== id)
    })
  }

  vacancyList() {
    return this.state.vacancy.map(currentvacancy => {
      return <Vacancy vacancy={currentvacancy} deleteVacancy={this.deleteVacancy} key={currentvacancy._id}/>;
    })
  }

  render() {
    return (
  <>

<main>
        <section id="intro" >
        <div className ="intro-container">
            <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/black.jpg)"}}>
          <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"200%"}}>Vacancy</h2>

           </div>
          </div>
            </div>
            </div>
            </div>
            </div>
        </section>
            <a href="vacancyadd"><button className="btn btn-primary" >Add Vacancy</button></a>

      <div>
        <h3>Logged Exercises</h3>
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>Username</th>
              <th>Description</th>
              <th>Duration</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            { this.vacancyList() }
          </tbody>
        </table>
      </div>
    
</main>
    </>
    )
  }
}