import React from 'react'
import { Route } from 'react-router'

function applyonline() {
    return(
        <>
        <main id="main">
            <section id="form">
                <div class="form-detail">
                    <div class="online">
                        <h1>APPLY ONLINE</h1>
                        <p>Thank you for your interest in working with us. Please check below for available job opportunities that meet your criteria and send your application by filling out the Job Application Form.</p>
                    </div>
                    <div class="form-container">
                    <form class="flex-container">
                            
							<div class="four-columns">
								<div class="content-margin">
		                       		<input type="text" name="name" placeholder="Full name" class="contact-form-control pointer-small"></input>
							   	</div>
							</div>
							
	                        <div class="four-columns">
		                        <div class="content-margin">
		                       		<input type="email" name="email" placeholder="Email address" class="contact-form-control pointer-small"></input>
			                    </div>
		                    </div>
                            
							<div class="twelve-columns">
	                       		<textarea placeholder="Enter your message" name="message" class="contact-form-control pointer-small"></textarea>
							</div>
                            <label for="et_pb_contact_brand_file_request_0" class="et_pb_contact_form_label">Enter</label>
                                <input type="file" id="et_pb_contact_brand_file_request_0" class="file-upload"></input>
	                        <div style={{ "text-align": "center", "margin":"0 auto"}}>
                                <a href="applyonline"><button class="btn-blue btn">Apply Online</button></a>
                            </div>
					</form>
                    </div>
                </div>
            </section>
        </main>
        </>
    )
}

export default applyonline;