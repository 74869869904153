import React from 'react';
import logo from './logo.svg';
import Home from "./components/Home";
import Login from "./components/Login";
import Career from "./components/career";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import Services from './components/Services';
import Contact from './components/Contact';
import About from './components/About';
import VacancyList from './components/VacancyList';
import Applyonline from './components/applyonline';
import Vacancy from './components/Vacancy';

function App() {
  
  
  return (
    <>
    <Router>
      <header id="header" class="fixed-top header-transparent">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-xl-11 d-flex align-items-center">
            <h2 class="logo mr-auto"><a href="/" style={{ "text-decoration":"none", "fontSize":"0.8em"}}>Smarty Pants Technologies</a></h2>
            <nav class="mobile-menu">
              <label for="show-menu" class="show-menu"><div class="lines"></div></label>
                <input type="checkbox" id="show-menu" />
                  <ul id="menu">
                  <li><NavLink exact to="/">Home</NavLink></li>
                  <li><NavLink to="/services">Services</NavLink></li>
                  <li><NavLink to="/aboutus">About Us</NavLink></li>
                  <li><NavLink  to="/contact">Contact Us</NavLink></li>
                  <li><NavLink  to="/career">career</NavLink></li>
                  
                </ul>
              </nav>
          </div>
        </div>
      </div>
    </header>
    
      <Route exact path = "/">
          <Home/>
      </Route>
      <Route exact path = "/services">
          <Services/>
      </Route>
      <Route exact path="/contact">
        <Contact />
      </Route>
      <Route exact path="/aboutus">
        <About />
      </Route>
      <Route exact path="/login">
        <Login/>
      </Route>
      <Route exact path="/career">
        <Career />
      </Route>
      <Route exact path="/applyonline">
        <Applyonline />
      </Route>
      <Route exact path="/vacancyadd">
        <Vacancy/>
      </Route>
      <Route exact path="/vacancy">
        <VacancyList />
      </Route>
    </Router>
    </>
  );
}

export default App;
