import React, { Component } from 'react';
import axios from 'axios';

class CreateVacancy extends Component {
  constructor(props) {
    super(props);

    this.onChangePostname = this.onChangePostname.bind(this);
    this.onChangeNoofPerson = this.onChangeNoofPerson.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      postname: '',
      noofPerson: '',
      description: ''
    }
  }

  onChangePostname(e) {
    this.setState({
      postname: e.target.value
    })
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value
    })
  }

  onChangeNoofPerson(e) {
    this.setState({
      noofperson: e.target.value
    })
  }


  onSubmit(e) {
    debugger;
    e.preventDefault();
    debugger;
    const postdata = {
      postname: this.state.postname,
      noofperson: this.state.noofperson,
      description: this.state.description
    }
    debugger;
    console.log(postdata);

    axios.post('http://localhost:5000/vacancy/add', postdata)
      .then(res => console.log(res.data));

      this.setState({
        postname: '',
        noofperson: '',
        description: ''
      })
  }

  render() {
    return (
        <>
    <main>
        <section id="intro" >
        <div className ="intro-container">
            <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/black.jpg)"}}>
          <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"200%"}}>Vacancy</h2>

           </div>
          </div>
            </div>
            </div>
            </div>
            </div>
        </section>
            
          <main id="main">
          <div>
                    <h3>Create New Post Log</h3>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group"> 
                        <label>Position name: </label>
                        <input type="text"
                            required
                            className="form-control"
                            value={this.state.postname}
                            onChange={this.onChangePostname} />
                        </div>
                        <div className="form-group">
                        <label>No of Person: </label>
                        <input type="text"
                            required
                            className="form-control"
                            value={this.state.noofPerson}
                            onChange={this.onChangeNoofPerson} />
                        </div>
                        <div className="form-group"> 
                        <label>Description: </label>
                        <textarea  type="text"
                            required
                            className="form-control"
                            value={this.state.description}
                            onChange={this.onChangeDescription}
                            />
                        </div>
                        
                        
                        <div className="form-group">
                        <input type="submit" value="Create Post Log" className="btn btn-primary" />
                        </div>
                    </form>
                </div>
          </main>

                
          </main>
           </>
    )
  }
}

export default CreateVacancy;