import React, { Component } from 'react'
import { Route } from 'react-router'
import axios from 'axios';

class Login extends Component {
  
    constructor(props) {
        super(props);
    
        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    
        this.state = {
          username: '',
          password:''
        }
      }
    
      onChangeUsername(e) {
        this.setState({
          username: e.target.value
        })
      }
      onChangePassword(e) {
        this.setState({
          password: e.target.value
        })
      }
    
      onSubmit(e) {
        e.preventDefault();
    
        const user = {
          username: this.state.username,
          password: this.state.password
        }
    
        console.log(user);
    
        axios.post('http://localhost:5000/users/add', user)
          .then(res => console.log(res.data));
    
        this.setState({
          username: '',
          password: ''
        })
      }
    
    render() {
        return (
            <>
               <section id="intro" >
         <div className ="intro-container">
         <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

<ol className="carousel-indicators"></ol>

<div className="carousel-inner" role="listbox">

  <div className="carousel-item active" style={{backgroundImage: "url(assets/img/intro-carousel/Contact.jpg)"}}>
  <div className="carousel-container">
    <div className="container">
      
    <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"200%"}}>Contact Us</h2>

   </div>
  </div>

    </div>
    </div>
    </div>
    </div>
</section>
    <main id="main">
            <div>
                <h3>Create New User</h3>
                <form onSubmit={this.onSubmit}>
                <div className="form-group"> 
                    <label>Username: </label>
                    <input  type="text"
                        required
                        className="form-control"
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                        />
                </div>
                <div className="form-group"> 
                    <label>Password: </label>
                    <input  type="password"
                        required
                        className="form-control"
                        value={this.state.password}
                        onChange={this.onChangePassword}
                        />
                </div>
                <div className="form-group">
                    <input type="submit" value="Save" className="btn btn-primary" />
                </div>
                </form>
            </div>
            </main>
            
            </>
        )
    }
}
export default Login;