import React, { Component } from 'react'
import { Route } from 'react-router'
import axios from 'axios';

function Career ()
{
    return (
        <>
         <section id="intro" >
            <div className ="intro-container">
            <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/career.jpeg)"}}>
          <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"250%"}}>Career With US</h2>
           </div>
          </div>
            </div>
            </div>
            </div>
            </div>
        </section>
            <main id="main">


<section id="about">
  <div class="container" data-aos="fade-up">

    <header class="section-header">
      <h3>Career</h3>
      <br/>
    </header>

    <div class="row about-cols">

    <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
        <div class="about-col">
          <h2 class="title">Software Engineer - Android</h2>
          <p> <b>Open Positions:</b> 2</p>
          <p><b> Description: </b> Design and build advanced applications for the Android platform Work with outside data sources and APIs Proven experience in Android app development</p>
          <p><b> Eligibility: </b> Fresher or experienced </p>
          
        </div>
    </div>

    <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
        <div class="about-col">
          <h2 class="title">Software Engineer - iOS</h2>
          <p> <b>Open Positions:</b> 2</p>
          <p><b>Description: </b> Design and build advanced applications for the iOS platform Work with outside data sources and APIs Proven experience in iOS app development</p>
          <p> <b>Eligibility: </b> Fresher or experienced </p>
          
        </div>
    </div>

    <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
        <div class="about-col">
          <h2 class="title">Software Engineer - Front end</h2>
          <p> <b> Open Positions: </b>2</p>
          <p> <b> Description:</b> Develop new user-facing features Build reusable code and libraries for future use Knowledge and working experience with any javascript technology, preferably React</p>
          <p> <b>Eligibility: </b>Fresher or experienced </p>
          
        </div>
    </div>
    </div>
    <div class="row about-cols">
      <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
          <div class="about-col">
            <h2 class="title">Software Engineer - Back end</h2>
            <p> <b>Open Positions:</b> 2</p>
            <p><b> Description:</b> Develop and manage well-functioning databases and applications Write effective APIs Knowledge and working experience with Java and Spring is required</p>
            <p><b> Eligibility:</b> Fresher or experienced </p>
            
          </div>
      </div>
    </div>
   
  </div>
</section>


            </main>
          </>
    )
}

export default Career;