import React from 'react'
import { Route } from 'react-router'

function Services() {
    return (
        <>
        <section id="intro" >
        <div className ="intro-container">
            <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/intro-carousel/Services.jpg)"}}>
          <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"200%"}}>Our Services</h2>

           </div>
          </div>
            </div>
            </div>
            </div>
            </div>
        </section>
            <main id="main">
          


            <section id="services">
      <div class="container" data-aos="fade-up">

        <header class="section-header wow fadeInUp">
          <h3>Our Services</h3>
          
        </header>
        <br/>
        <div class="row">

          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-web"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>Website/Web App/SaaS</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-smart-phone" ></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>Mobile App Development Android & iOS</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="300">
            <div class="icon"><i class="icofont-code"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>Back-end Development</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-cart"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>E-Commerce Solution</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="300">
            <div class="icon"><i class="icofont-responsive"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>UI/UX Design</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="400">
            <div class="icon"><i class="ion-ios-people-outline"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>App Prototype and Strategy</a></h4>
            
          </div>

        </div>

      </div>
    </section>


    <section id="services">
      <div class="container" data-aos="fade-up">

        

        <div class="row">
        <div class="container text-center" data-aos="zoom-in">
        <div class="clearfix">
          <h1>Mobile Application</h1>
        <p>
          <img src="assets/img/portfolio/mobileapp.png" alt="" class="pull-left mr-2" />
          With specialized and experienced developers for Android and iOS Applications, we are equipped to develop the most useful and attractive mobile applications for your business. Our expert team of android & iOS developers perform deep analysis to meet all the business requirements of the client. Our experience in Android, iOS and Cross-platform app development services makes us unique and an apt choice for your business.
        </p>
        </div>
        </div>
        </div>

        <div class="row">
        <div class="container text-center" data-aos="zoom-in">
        <div class="clearfix">
          <h1>Web Development</h1>
        <p>
          <img src="assets/img/portfolio/webdevlopement.jpg" alt="" class="pull-right mr-2 " />
          Smarty Pants Technologies is the best choice for professional website design, development and maintenance which focuses on reaching your business goals. We are experienced in web page design, developing and hosting websites that suit your requirements. If you are looking for a web development company for your enterprise who understand all your requirements and provide you a solution accordingly, then we are the one.
        </p>
        </div>
        </div>
        </div>


        <div class="row">
        <div class="container text-center" data-aos="zoom-in">
        <div class="clearfix">
          <h1>Responsive Designing</h1>
        <p>
          <img src="assets/img/portfolio/responsive.png" alt="" class="pull-left mr-2" />
          All the web applications we build are responsive. This means they look great and work perfectly on any screen of any size; desktop, table or smartphone. We do this by adapting menus, controls and other visuals to fit the device based on the current screen width and height, ensuring the user always gets the best possible experience regardless of what device they’re using.
        </p>
        </div>
        </div>
        </div>
      </div>
      </section>

          </main>
        </>
    )
}

export default Services
