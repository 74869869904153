import React from 'react'
import { Route } from 'react-router'

function About() {
  
    return (
        <>
         <section id="intro" >
            <div className ="intro-container">
            <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/intro-carousel/About.jpg)"}}>
          <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"200%"}}>About Us</h2>

           </div>
          </div>
            </div>
            </div>
            </div>
            </div>
        </section>
            <main id="main">
{/* 
<section id="featured-services">
  <div class="container">
    <div class="row">

      <div class="col-lg-4 box">
        <i class="ion-ios-bookmarks-outline"></i>
        <h4 class="title"><a href="">Research</a></h4>
        <p class="description">We ask questions, discuss and prototype, as changing ideas is cheaper than changing products. As well as challenging usual solutions to find best fit approach for each project.</p>
      </div>

      <div class="col-lg-4 box box-bg">
        <i class="ion-ios-analytics-outline"></i>
        <h4 class="title"><a href="">Our Workflow</a></h4>
        <p class="description">We are about great future of technologies and desire to affect it and shape great are about great future of technology. Iteratively improve IT-products until we achieve all business.</p>
      </div>

      <div class="col-lg-4 box">
        <i class="ion-ios-heart-outline"></i>
        <h4 class="title"><a href="">Delivery</a></h4>
        <p class="description">We focus on delivering on time and our responsibility to client and other teams. Our software developers try to deliver MVP as soon as possible, analyze customers feedback and do iterative improvements.</p>
      </div>

    </div>
  </div>
</section> */}


<section id="services">
      <div class="container" data-aos="fade-up">

        <header class="section-header wow fadeInUp">
          <h3>Why Smarty Pants</h3>
        </header>
        <div class="row">
            <div class="container text-center" data-aos="zoom-in">
                <p>
                Smarty Pants Technologies is known for its services like Mobile Application Development, Website Development, SaaS and for providing End-To-End Solutions. Our Web and Mobile Development professionals are committed to provide work which pushes the limits of creativity and at a quick turnaround time with utmost quality. We are team of highly experienced individuals who have knowledge on cutting edge technology. We have expertise in Online Store, eCommerce Portal, Business Websites, Android and iPhone application development etc. Our online reputation is founded on the ability to deliver web services with latest technologies like HTML5, CSS3, Angular, ReactJs, NodeJs, Python, Android, Spring Boot, Swift, Flutter and many more with custom solutions based on client’s requirement.
                </p>
                </div>
                </div>
        
      </div>
    </section>


<section id="about">
  <div class="container" data-aos="fade-up">

    <header class="section-header">
      <h3>About Us</h3>
      <br/>
    </header>

    <div class="row about-cols">

      <div class="col-md-4" data-aos="fade-up" data-aos-delay="100">
        <div class="about-col">
          <div class="img">
            <img src="assets/img/about-mission.jpg" alt="" class="img-fluid" />
            <div class="icon"><i class="ion-ios-speedometer-outline"></i></div>
          </div>
          <h2 class="title"><a href="#">Our Mission</a></h2>
          <p>
          {/* Our mission is to provide the reliable, competitive advantage of IT services to our clients around the world. */}
          Our mission is to enhance the business growth of our customers with market-defining high-quality solutions.
          </p>
        </div>
      </div>

      <div class="col-md-4" data-aos="fade-up" data-aos-delay="200">
        <div class="about-col">
          <div class="img">
            <img src="assets/img/about-plan.jpg" alt="" class="img-fluid" />
            <div class="icon"><i class="ion-ios-list-outline"></i></div>
          </div>
          <h2 class="title"><a href="#">Our Plan</a></h2>
          <p>
          Our software development company makes detailed planning for each project, and keep transparency till the very end. 
          </p>
        </div>
      </div>

      <div class="col-md-4" data-aos="fade-up" data-aos-delay="300">
        <div class="about-col">
          <div class="img">
            <img src="assets/img/about-vision.jpg" alt="" class="img-fluid" />
            <div class="icon"><i class="ion-ios-eye-outline"></i></div>
          </div>
          <h2 class="title"><a href="#">Our Core Values</a></h2>
          <p>
          We believe in delivering customer satisfaction and we don’t refrain from re-working if that adds value to the project and is utterly necessary. 
          </p>
        </div>
      </div>

    </div>

  </div>
</section>

            </main>
          </>
    )
}

export default About;