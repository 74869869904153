import React from 'react'
// import { useSpring, animated } from 'react-spring'
// import { useTransition } from 'react-spring'
import Anime from '@mollycule/react-anime';


function Home() {

 
  const seperator = (e) => {
    return e.split('')//.map((e, index) => {e})
  }


  var size = 0

  const heading1_data = seperator('We bring your ideas to life');
  
  heading1_data.map((e,i) => {
    console.log(i)
  })

  size = heading1_data.length;
  
  var heading1 = 
    heading1_data.map((el, index) => (
      <Anime
      in
      appear
      duration={5000}
      onEntering={{
        // opacity :[1,0],
        color: ['#ff0000','#ffffff'],
        easing: "linear",
        direction:"alternate",
        loop: true,
        delay: (index/size) * 2500 //parseInt(index * 2500 / size)
      }} 
      >
        {<span key={index}>{el}</span>}
      </Anime>
    ))
  

    return (
        <>
  <section id="intro">
    <div className="intro-container">
      <div id="introCarousel" data-interval="5000" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/intro-carousel/slider1.jpg)"}}>
            <div className="carousel-container">
              <div className="container">
                <h2 class="ml12 moving-letters animate__animated animate__fadeInUp"  style={{ "font-size":"400%"}} >Welcome To Smarty Pants</h2>
              <h2 class="animate__animated animate__fadeInDown" style={{ "font-size":"180%"}}>We bring your ideas to life</h2>
                    {/* <h2>
                      {heading1}
                    </h2> */}
                  {/* </div> */}
                
                
              </div>
            </div>
          </div>

          <div className="carousel-item" style={{backgroundImage: "url(assets/img/intro-carousel/slider2.jpg)"}}>
            <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"180%"}}>Long term partner for designing, developing and maintaining your mobile or web app.</h2>

           
          </div>
                    {/* <h2>
                    {
                      seperator('LLong term parters for designing, developing and maintaining your mobile or web app').map((el, index) => (
                        <Anime
                        in
                        appear
                        duration={2000}
                        onEntering={{
                          opacity :[0.1, 1],
                          easing: "linear",
                        }} 
                        >
                          {<span key={index}>{el}</span>}
                        </Anime>
                      ))
                    }
                    </h2> */}
                  {/* </div> */}
                
                
              {/* </div> */}
            </div>
          </div>

          <div className="carousel-item" style={{backgroundImage: "url(assets/img/intro-carousel/slider3.jpg)"}}>
            <div className="carousel-container">
            <div className="container">
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"180%"}}>We provide AI powered, end-to-end, e-commerce solution that help brands setup and grow their online business</h2>
                    {/* <h2>
                    {
                      seperator('We provide AI powered, end-to-end e-commerce solution that help brands setup and grow their online business').map((el, index) => (
                        <Anime
                        in
                        appear
                        duration={2000}
                        onEntered={{
                          opacity :[1, 0.1, 1],
                          easing: "linear",
                          loop: true,
                          delay: index * 10
                        }} 
                        >
                          {<span key={index}>{el}</span>}
                        </Anime>
                      ))
                    }
                    </h2> */}
                  {/* </div> */}
                
                
              </div>
            </div>
          </div>

          
        </div>

        <a className="carousel-control-prev" href="#introCarousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon ion-chevron-left" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>

        <a className="carousel-control-next" href="#introCarousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon ion-chevron-right" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>

      </div>
    </div>
    
  </section>

  <section id="call-to-action">
      <div class="container text-center" data-aos="zoom-in">
        <h3>Why Smarty Pants Technology</h3>
        <p> We Transform, Build and Manage World-Class Digital Experiences for leading brands and emerging startups. A proven and reliable vendor choice, Smarty Pants Technologies is passionate in its mission to create attractive, resilient products for its clients. Its powerhouse team was handpicked for specialties in mobile and web app development.</p>
        <a class="cta-btn" href="contact" style={{ "text-decoration":"none"}}>Contact Us</a>
      </div>
    </section>

    <section id="services">
      <div class="container" data-aos="fade-up">

        <header class="section-header wow fadeInUp">
          <h3>Our Services</h3>
          
        </header>
        <br/>

        <div class="row">

          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="100">
            <div class="icon"><i class="icofont-web"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>Website/Web Apps/SaaS</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-smart-phone" ></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>Mobile App Development Android & iOS</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="300">
            <div class="icon"><i class="icofont-code"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>Back-End Development</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="200">
            <div class="icon"><i class="icofont-cart"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>E-Commerce Solution</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="300">
            <div class="icon"><i class="icofont-responsive"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>UI/UX Design</a></h4>
            
          </div>
          <div class="col-lg-4 col-md-6 box" data-aos="fade-up" data-aos-delay="400">
            <div class="icon"><i class="ion-ios-people-outline"></i></div>
            <h4 class="title"><a href="" style={{ "text-decoration":"none"}}>App Prototype and Strategy</a></h4>
            
          </div>

        </div>

      </div>
    </section>
              
                   
              
         

  <section id="portfolio" class="section-bg">
      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h3 class="section-title">Technologies</h3>
        </header>
        <br/>
        <div class="row" data-aos="fade-up" data-aos-delay="100">
      <div class=" col-lg-12">
          {/* <ul id="portfolio-flters">
            <li data-filter="*" class="filter-active">All</li>
            <li data-filter=".filter-app">App</li>
            <li data-filter=".filter-card">Card</li>
            <li data-filter=".filter-web">Web</li>
          </ul> */}
        </div>
      </div>

      <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

        <div class="col-lg-4 col-md-6 portfolio-item filter-app" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/android.jpg" class="img-fluid" alt="" />
              
            </figure>

            <div class="portfolio-info">
              <h4>Android</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/angular.png" class="img-fluid" alt="" />
              
            </figure>

            <div class="portfolio-info">
              <h4>Angular</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/react.png" class="img-fluid" alt="" />
            </figure>

            <div class="portfolio-info">
              <h4>React js/Native</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/node.jpg" class="img-fluid" alt="" />
              
            </figure>

            <div class="portfolio-info">
              <h4>Node js</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/Flutter.png" class="img-fluid" alt="" />
              
            </figure>

            <div class="portfolio-info">
              <h4>Flutter</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/swift.png" class="img-fluid" alt="" />
              
            </figure>

            <div class="portfolio-info">
              <h4>Swift</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/python.png" class="img-fluid" alt="" />
             
            </figure>

            <div class="portfolio-info">
              <h4>Python</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/spring-framework.png" class="img-fluid" alt="" />
             
            </figure>

            <div class="portfolio-info">
              <h4>Spring boot</h4>
              
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card" data-aos="fade-up">
          <div class="portfolio-wrap">
            <figure>
              <img src="assets/img/clients/mongodb.jpg" class="img-fluid" alt="" />
             
            </figure>

            <div class="portfolio-info">
              <h4>MongoDB</h4>
              
            </div>
          </div>
        </div>

      </div>

      </div>
    </section>

    <section id="skills">
      <div class="container" data-aos="fade-up">

        <header class="section-header">
          <h3>Our Process</h3>
          
        </header>

        <div class="skills-content">

          <div class="">
            <div class="container">
              
              <div class="row align-items-center how-it-works d-flex">
                <div class="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
                  <div class="circle font-weight-bold">1</div>
                </div>
                <div class="col-6">
                  <h5>Conceptualization</h5>
                  <p>Each brand is unique, each product is unique. Yes, we understand you. Thus, we spend some time initially to understand your business goals and requirements. Accordingly, a development strategy and road-map is created specifically tailored to your goal.</p>
                </div>
              </div>
             
              <div class="row timeline">
                <div class="col-2">
                  <div class="corner top-right"></div>
                </div>
                <div class="col-8">
                  <hr/>
                </div>
                <div class="col-2">
                  <div class="corner left-bottom"></div>
                </div>
              </div>
           
              <div class="row align-items-center justify-content-end how-it-works d-flex">
                <div class="col-6 text-right">
                  <h5>Designing</h5>
                  <p>Our UI/UX team creates unique and modern designs that bring strategy, design and technology altogether to build products with great user experiences.
                    Our Designers create the design wireframes by following industry standards and proper design guidelines to provide engaging content and meaningful experience to your target audience.</p>
                </div>
                <div class="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                  <div class="circle font-weight-bold">2</div>
                </div>
              </div>
         
              <div class="row timeline">
                <div class="col-2">
                  <div class="corner right-bottom"></div>
                </div>
                <div class="col-8">
                  <hr/>
                </div>
                <div class="col-2">
                  <div class="corner top-left"></div>
                </div>
              </div>
             
              <div class="row align-items-center how-it-works d-flex">
                <div class="col-2 text-center top d-inline-flex justify-content-center align-items-center">
                  <div class="circle font-weight-bold">3</div>
                </div>
                <div class="col-6">
                  <h5>Development</h5>
                  <p>Our development teams - both frontend and backend,choose the best technologies for your products and make your product and the UI/UX wireframes come alive. </p>
                </div>
              </div>
            </div>
          </div>
              

        </div>

      </div>
    </section>

  
        </>
    )
}

export default Home;
