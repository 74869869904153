import React, { Component } from 'react'
import axios from 'axios';

class Contact extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             name:'',
             email: '',
             msg:'',
             responseMsg:''
        }
    }

    changeHandler = (e) =>
    {
        this.setState({[e.target.name]:e.target.value})
        console.log(this.state)
    }

    submitHandler = e =>{
        e.preventDefault()
        console.log(this.state)
        var parameters = 'name='+this.state.name+'&email='+this.state.email+'&msg='+this.state.msg;
        axios.get('https://script.google.com/macros/s/AKfycbzHCgjbgz3WMaoI5YSoLc6PZKcZX2YBw2xKV1E0JkG61XiOcY7u/exec?'+parameters)
        .then(response =>{
            console.log(response)
            if(response.data.data === 'done'){
              this.setState({responseMsg: 'Your message has been sent successfully'})
            } else {
              this.setState({responseMsg: 'Something went wrong'})
            }
        })
        .catch(error => {
            console.log(error)
        })
    }
    
    render() {
        var { name,email,msg} = this.state
        return (
            <>
            <section id="intro" >
         <div className ="intro-container">
            <div id="introCarousel" className="carousel  slide carousel-fade" data-ride="carousel">

        <ol className="carousel-indicators"></ol>

        <div className="carousel-inner" role="listbox">

          <div className="carousel-item active" style={{backgroundImage: "url(assets/img/intro-carousel/Contact.jpg)"}}>
          <div className="carousel-container">
            <div className="container">
			  
            <h2 class="ml12 moving-letters animate__animated animate__fadeInUp" style={{ "font-size":"200%"}}>Contact Us</h2>

           </div>
          </div>

            </div>
            </div>
            </div>
            </div>
        </section>
            <main id="main">
            <section id="contact" class="section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-header">
          <h3>Contact Us</h3>
          <p>Tell us your business goals and we’ll see how we can create something amazing together.</p>
        </div>

        <div class="row contact-info">

          <div class="col-md-4">
            <div class="contact-address">
            <a href="https://goo.gl/maps/sBMUuZR5XSAmyxif9" style={{ "text-decoration":"none"}}><i class="ion-ios-location-outline"></i></a>
              <h3><a href="https://goo.gl/maps/sBMUuZR5XSAmyxif9" style={{ "text-decoration":"none"}}>Address</a></h3>
              <address><a href="https://goo.gl/maps/sBMUuZR5XSAmyxif9" style={{ "text-decoration":"none"}}>412, Shubh Universal, Vesu Main Road, Vesu, Surat, Gujarat.</a></address>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-phone">
              <i class="ion-ios-telephone-outline"></i>
              <h3><a href="#" style={{ "text-decoration":"none"}}>Phone Number</a></h3>
              <p><a href="tel:+155895548855" style={{ "text-decoration":"none"}}>+91 85111 08666</a></p>
            </div>
          </div>

          <div class="col-md-4">
            <div class="contact-email">
              <i class="ion-ios-email-outline"></i>
              <h3><a href="#" style={{ "text-decoration":"none"}}>Email</a></h3>
              <p><a href="mailto:info.smartypantstech@gmail.com" style={{ "text-decoration":"none"}}>info.smartypantstech@gmail.com</a></p>
            </div>
          </div>

        </div>
        {this.state.responseMsg}
        <div class="form" >
          <form  role="form" class="php-email-form" onSubmit={this.submitHandler}>
            <div class="form-row">
              <div class="form-group col-md-6">
                <input type="text" class="form-control" id="name" name="name" value={name} onChange={this.changeHandler} placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" required />
                <div class="validate"></div>
              </div>
              <div class="form-group col-md-6">
                <input type="email" class="form-control" id="email" name="email" value={email} onChange={this.changeHandler}  placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" required/>
                <div class="validate"></div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" required/>
              <div class="validate"></div>
            </div>
            <div class="form-group">
              <textarea class="form-control" rows="5" data-rule="required" name="msg" value={msg} onChange={this.changeHandler} data-msg="Please write something for us" placeholder="Message" required></textarea>
              <div class="validate"></div>
            </div>
            <div class="mb-3">
              <div class="loading">Loading</div>
              <div class="error-message"></div>
        {/* <div class="sent-message"> */}
          
          {/* </div> */}
            </div>
            <div class="text-center"><button type="submit">Send Message</button></div>
          </form>
        </div>
      </div>
    </section>
</main>
        
            </>
        )
    }
}

export default Contact;
